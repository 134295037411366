import { stringify } from 'qs';
import { adminApi, api } from '../../plugins/axios';
import { PaginationDto } from '../../types';
import { PATHS } from './paths';
import { CreateUserDto, UpdateUserDto } from './type';

export function createUser(createUserDto: CreateUserDto) {
  return api.post(PATHS.ROOT, createUserDto);
}

export function updateMe(updateUserDto: UpdateUserDto) {
  return api.patch(PATHS.ME, updateUserDto);
}

export function updateUserByAdmin(updateUserDto: UpdateUserDto) {
  const { id } = updateUserDto;
  return adminApi.patch(PATHS.getOneById(id), updateUserDto);
}

export function removeUserByAdmin(id: number) {
  return adminApi.delete(PATHS.getOneById(id));
}

export function getAllUsersByAdmin(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}

export function downloadUserExcel() {
  return adminApi.get(`/${PATHS.USER_EXCEL}`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });
}
