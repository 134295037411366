import { useState } from 'react';
import { format } from 'date-fns';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { downloadUserExcel } from '../../../api/user';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { SearchUser } from '../../../components/SearchUser';
import { Table } from '../../../components/Table';
import { ITEMS_PER_PAGE_LG } from '../../../constants';
import { useDebounce } from '../../../hooks';
import { useAllUsersByAdmin } from '../../../hooks/user';
import AdminH1 from '../../components/AdminH1';
import { ExcelDownButton } from '../../components/ExcelDownButton';

export const UserList = () => {
  const [searchData, setSearchData] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const debouncedSearchUser = useDebounce({ value: searchData, delay: 250 });
  const { data: allUsers } = useAllUsersByAdmin({
    page,
    itemsPerPage: ITEMS_PER_PAGE_LG,
    filter: { name: debouncedSearchUser },
  });

  return (
    <>
      <div className="flex items-center justify-between py-2 px-8">
        <AdminH1>회원관리</AdminH1>
        <SearchUser
          searchData={searchData}
          setSearchData={(data: string) => setSearchData(data)}
        />
      </div>

      <div className="self-end">
        <ExcelDownButton
          type="button"
          text="유저 엑셀 다운로드"
          onClick={() => {
            downloadUserExcel().then((res) => {
              const url = URL.createObjectURL(res.data);
              const link = document.createElement('a');
              link.href = url;
              link.download = `유저_${format(new Date(), 'yyyyMMddHHmmss')}`;
              link.click();
              URL.revokeObjectURL(url);
            });
          }}
        />
      </div>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>이름</Table.Th>
              <Table.Th>아이디</Table.Th>
              <Table.Th>가입일시</Table.Th>
              <Table.Th>휴대폰번호</Table.Th>
              <Table.Th>누적 결근</Table.Th>
              <Table.Th>누적 지원</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {allUsers &&
              map(allUsers.items, (user) => (
                <Table.Row key={user.id}>
                  <Table.Td>{user.id}</Table.Td>
                  <Table.Td>{user.name}</Table.Td>
                  <Table.Td>{user.email}</Table.Td>
                  <Table.Td>
                    {format(new Date(user.createdAt), 'yyyy-MM-dd HH:mm')}
                  </Table.Td>
                  <Table.Td>{user.phone}</Table.Td>
                  <Table.Td>{user.notWorkCount}</Table.Td>
                  <Table.Td>{user.applicationCount}</Table.Td>
                  <Table.Td className="text-right">
                    <Link
                      className="font-normal text-indigo-600 hover:text-indigo-900"
                      to={`/admin/users/${user.id}/info`}
                    >
                      상세보기
                    </Link>
                  </Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card>
      {allUsers?.items.length !== 0 && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE_LG}
          setPage={setPage}
          totalItemCount={allUsers?.pagination?.totalItemCount || 0}
          page={page}
        />
      )}
    </>
  );
};
